import React from 'react'
import AdminUsersEdit from '../../components/admin/users/admin-users-edit'
import AdminTemplate from '../../templates/admin-template'


const AdminUsersEditPage = () => {
  return (
    <AdminTemplate>
        <AdminUsersEdit/>
    </AdminTemplate>
  )
}

export default AdminUsersEditPage